import {mapGetters} from 'vuex'

export default {
  name: 'review',

  data() {
    return {}
  },

  mounted() {
    if (!this.getUploadImportFileData()) {
      this.$router.push({name: 'ImportLegalDocumentUpload'})
    }
  },

  computed: {
    reviewMappedFields() {
      return this.getUploadImportFileData()?.legalDocument.formFields;
    }
  },

  methods: {
    ...mapGetters([
      'getUploadImportFileData'
    ]),

    fieldMappingStatus(field) {
      if (field.isIgnored) return this.$t('ignored')
      else if (field.isMapped) return this.$t('mapped')
      else return this.$t('pending')
    }
  }
}